

















































import Vue from "vue";
import { Form, Field, Checkbox, CheckboxGroup, Button, Radio, RadioGroup, Switch, Popup, Picker, Toast, Dialog, DatetimePicker } from "vant";
import Moment from "moment";
Moment.locale("zh-cn");

export default Vue.extend({
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Switch.name]: Switch,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Dialog.Component.name]: Dialog.Component,
    [DatetimePicker.name]: DatetimePicker
  },
  data() {
    return {
      id: this.$route.params.id || 0,
      type: this.$route.query.type || "",
      form: {
        id: 0,
        name: "",
        businessId: 0,
        platformId: 0,
        userId: 0,
        day: 31,
        dueTime: Moment()
          .startOf("day")
          .toDate(),
        price: 0,
        autoPay: false,
        service: 0,
        dueDelayDay: 0
      },
      services: [] as string[],
      targetName: "",
      userName: "",
      userSearch: {
        show: false,
        keyword: ""
      },
      businessPicker: {
        show: false,
        list: [],
        default: 0,
        loading: true
      },
      platformPicker: {
        show: false,
        list: [],
        default: 0,
        loading: true
      },
      dueTimePicker: {
        show: false
      }
    };
  },
  watch: {
    "businessPicker.show": "onBusinessPickerShow",
    "platformPicker.show": "onPlatformPickerShow"
  },
  created() {
    if (!this.type) {
      Toast.fail("访问参数异常");
      this.$router.go(-1);
      return;
    }
    if (this.id != 0) {
      this.getData().catch(() => {
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      });
    }
  },
  methods: {
    getData() {
      return this.$axios.post("/api/manage/servicePlan/getPlan", { servicePlanId: this.id }).then(res => {
        const plan = res.data.data;
        if (plan.businessPlan) {
          this.targetName = plan.business.name;
        }
        if (plan.platformPlan) {
          this.targetName = plan.platform.name;
        }
        const user = plan.user;
        this.userName = `${user.name ? user.name : user.nickName}(${user.phone})`;

        this.form = Object.assign({}, this.form, {
          id: plan.id,
          name: plan.name,
          businessId: plan.businessId,
          platformId: plan.platformId,
          userId: plan.userId,
          day: plan.day,
          dueTime: new Date(plan.dueTime - 86400 * 1000),
          autoPay: Boolean(plan.autoPay),
          price: plan.price / 100,
          service: plan.service,
          dueDelayDay: plan.dueDelayDay
        });
        const service = plan.service;
        const services = [];
        if ((service & 1) > 0) services.push("1");
        if ((service & 2) > 0) services.push("2");
        if ((service & 4) > 0) services.push("4");
        if ((service & 240) > 0) services.push("240");
        if ((service & 256) > 0) services.push("256");
        if ((service & 512) > 0) services.push("512");
        this.services = services;
      });
    },
    save() {
      const data = Object.assign({}, this.form, {
        dueTime: this.form.dueTime.getTime() + 86400 * 1000,
        autoPay: this.form.autoPay ? 1 : 0,
        price: Math.floor(this.form.price * 100)
      });
      return this.$axios.post("/api/manage/servicePlan/savePlan", data).then(res => {
        const plan = res.data.data;
        this.id = plan.id;
        this.form.id = plan.id;
        Toast.success("保存成功");
      });
    },
    onServicesChange(services: string[]) {
      let service = 0;
      for (const s of services) {
        service = service | parseInt(s);
      }
      this.form.service = service;
    },
    onSearchUser() {
      if (this.form.platformId == 0) {
        Toast.fail("请先选择对象");
        return;
      }
      this.$axios.post("/api/manage/findUser", { keyword: this.userSearch.keyword, platformId: this.form.platformId }).then(res => {
        const user = res.data.data;
        this.userName = `${user.name ? user.name : user.nickName}(${user.phone})`;
        this.form.userId = user.id;
      });
    },
    showPicker() {
      if (this.id != 0) {
        Toast.fail("无法修改");
        return;
      }
      if (this.type == "business") {
        this.businessPicker.show = true;
      } else if (this.type == "platform") {
        this.platformPicker.show = true;
      } else {
        Toast.fail("没有权限");
      }
    },
    onBusinessPickerShow(show: boolean) {
      if (show && this.businessPicker.loading) {
        this.$axios.post("/api/manage/getBusinessListForPick").then(res => {
          this.businessPicker.list = res.data.data;
          this.businessPicker.loading = false;
        });
      }
    },
    onPlatformPickerShow(show: boolean) {
      if (show && this.platformPicker.loading) {
        this.$axios.post("/api/manage/getPlatformListForPick").then(res => {
          this.platformPicker.list = res.data.data;
          this.platformPicker.loading = false;
        });
      }
    },
    onPickBusiness(value: { id: number; name: string; platformId: number }) {
      this.targetName = value.name;
      this.form.businessId = value.id;
      this.form.platformId = value.platformId;
      this.businessPicker.show = false;
    },
    onPickPlatform(value: { id: number; name: string }) {
      this.targetName = value.name;
      this.form.businessId = 0;
      this.form.platformId = value.id;
      this.platformPicker.show = false;
    },
    formatTime(time: Date) {
      return Moment(time).format("ll");
    }
  }
});
